import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { TileSection, Tile } from '../../components/tile-section';
import Discover from '../../components/discover';
import ServiceAndMaintenance from '../../components/service-and-maintenance';

import { graphql } from 'gatsby';
import ATillZ from '../../components/a-till-z-cta';
import { Languages } from '../../tools/languages';

const CategoryTiles = ({ categories }) => {
  return (
    <TileSection>
      {categories.map((category) => {
        const { title, slug, image } = category.node;
        return (
          <Tile
            key={title}
            img={image ? image.childImageSharp.fluid : 'https://via.placeholder.com/640?text=Bavak'}
            to={slug === 'support' ? `/${slug}/` : slug}
            title="Solutions"
            name={title}
          ></Tile>
        );
      })}
    </TileSection>
  );
};

const EnglishSolutionsPage = ({ location, data, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={data.page.SEO.title}
        description={data.page.SEO.description}
      />
      <Navigation />
      <main>
        <h1>{data.page.SEO.title}</h1>
        <CategoryTiles categories={data.categories.edges} />
        <ServiceAndMaintenance />
        <hr />
        <ATillZ language={language} />
        <Discover language={language} dark={true} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    page: strapiSolutionspageEn {
      SEO {
        title
        description
      }
    }
    categories: allStrapiSolutionCategories(sort: { fields: slug, order: ASC }) {
      edges {
        node {
          slug
          title: title_en
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default EnglishSolutionsPage;
